<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
            <el-option label="待开票" :value="0"></el-option>
          <el-option label="已开" :value="1"></el-option>
          <el-option label="开票失败" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="时间">
        <el-date-picker
                v-model="queryTime"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
      </el-form-item>
      <el-form-item label="用户">
          <user-select :userid.sync="user_id"></user-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
       
      </el-form-item>
      <el-form-item>
         <excelExport
            ref="export"
            :tableName="tableName"
            :values="values"
            :titles="titles"
            @startExport="startExport"
          >
            <div>
              <span>导出时间</span>
              <el-date-picker
                v-model="exportTime"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </excelExport>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #activity_time="{ row }">
        <div>
          <p>开始:{{ row.start_time }}</p>
          <p>结束:{{ row.end_time }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 1">下架</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 2"
            >上架中</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="signleExport(row)"
            >导出</el-button
          >
           <el-button type="primary"  v-if="row.status != 2" size="small" @click="getOrderList(row)"
            >查看订单</el-button
          >
            <el-button v-if="row.status == 0" type="success" size="small" @click="success(row)"
            >开票成功</el-button>
             <el-button v-if="row.status == 0" type="warning" size="small" @click="openFail(row)"
            >开票失败</el-button>
        </div>
      </template>
    </auto-table>

    
<el-dialog
  title="失败原因"
  :visible.sync="failShow"
  width="30%">

 <el-input
  type="textarea"
  :rows="2"
  placeholder="请输入内容"
  v-model="remark">
</el-input>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="fail">确 定</el-button>
  </span>
</el-dialog>


<el-dialog
  title="开票订单"
  :visible.sync="orderShow"
  width="60%">
     <auto-table
      :DataList="orderList"
      :option="oOption"
    >
    </auto-table>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="orderShow = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import excelExport from "@/components/excel/excelExport.vue";
export default {
  components: {
    excelExport,
  },
  data() {
    return {
        failShow:false,
        remark:'',
        cur:{},
        queryTime:[],
        user_id:'',
      DataList: [],
      Option: [
        { name: "抬头", value: "title" },
        { name: "金额", value: "amount" },
        { name: "税号", value: "tax_number" },
        { name: "邮箱", value: "email" },
        { name: "状态", value: "status",type: "custom"  },
        { name: "提交时间", value: "create_time" },
         { name: "说明", value: "remark" },
        { name: "操作", value: "handler", type: "custom" ,width:'300'},
      ],
      Total: 0,
      status: 0,
       tableName: "海马运动发票",
      values: [
        "flowing_no",
        "title",
        "amount",
        "course_num",
        "finished_time",
         "create_time",
        "coupon_cash",
          "consume",
          "pay_type",
          "source",
          "i_title",
          "tax_number",
          "email"
      ],
      titles: ["订单号", "商品", "订单金额","数量/课节数","支付时间" , "下单时间","优惠金额","使用消费金","支付方式","下单平台","抬头","税号","邮箱"],
      exportTime: [],
      sourceList: [
        {
          value: "sport",
          label: "微信小程序",
        },
        {
          value: "dy_sport",
          label: "抖音小程序",
        },
        {
          value: "android",
          label: "安卓APP",
        },
        {
          value: "ios",
          label: "ios APP",
        },
        {
          value: "pc",
          label: "pc",
        },
        {
          value: "coach_sport",
          label: "教练小程序",
        },
        {
          value: "coach_android",
          label: "教练局_android",
        },
        {
          value: "coach_ios",
          label: "教练局_ios",
        },
      ],
      orderShow:false,
orderList:[],
oOption: [
        { name: "订单号", value: "flowing_no" },
        { name: "商品", value: "title" },
        { name: "金额", value: "amount" },
        { name: "数量", value: "course_num" },
        { name: "支付方式", value: "pay_type" },
        { name: "支付时间", value: "finished_time" },
      ],
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    getOrderList(row){
        this.$get('/user/rechargerecord/query',{invoice_id:row.id}).then(res=>{
            this.orderList = res.data.data
            this.orderShow = true
        })
    },
    openFail(row){
        this.failShow = true
        this.cur = row
    },
     fail(){
        if(!this.remark){
            this.$message('请填写失败原因')
            return
        }

        this.$post('/user/invoice/update',{id:this.cur.id,status:2,remark:this.remark}).then(res=>{
               this.getList(1);
               this.failShow = false
        })
    },
    // 导出表格
    startExport() {
       if (this.exportTime && this.exportTime.length ==2) {
       
      }else{
        this.$message('请选择时间')
        return
      }

      this.exportInfo();
    },
    exportInfo() {
      let params = {
        start_time : this.exportTime[0]+' 00:00:00',
         end_time : this.exportTime[1]+' 23:59:59'
      };
     
      this.$post(
        "/user/invoice/queryExportList",
        params,
      ).then((res) => {
        let DataList = res.data.data;
        DataList.forEach((item) => {
          item.source = this.Paysource(item)
        });
        this.$refs.export.importExcel(DataList);
      });
    },

    signleExport(row){
      let params = {
       id:row.id
      };
     
      this.$post(
        "/user/invoice/queryExportList",
        params,
      ).then((res) => {
        let DataList = res.data.data;
        DataList.forEach((item) => {
          item.source = this.Paysource(item)
        });
        this.$refs.export.importExcel(DataList);
      });
    },
    success(row){
        this.$post('/user/invoice/update',{id:row.id,status:1}).then(res=>{
           row.status = 1
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      let param = {
            currentPage: page,
          pageSize: 10,
          status:this.status || null,
          user_id:this.user_id || null
      }

       if (this.queryTime && this.queryTime.length ==2) {
        param.start_time = this.queryTime[0]+' 00:00:00'
        param.end_time = this.queryTime[1]+' 23:59:59'
      }

      
      this.$axios({
        url: "/user/invoice/queryManagerListPage",
        params:param
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
     Paysource(row) {
      let source = row.source;
      let pay = row.pay_type;
      if (source == "sport" && pay == "dy") {
        return "抖音小程序";
      } else if (source == "sport" && pay != "dy") {
        return "微信小程序";
      } else {
        let r = "";
        this.sourceList.forEach((item) => {
          if (item.value === row.source) {
            r = item.label;
          }
        });
        return r || row.source;
      }
    },
  },
};
</script>

<style lang="less" scoped>
</style>